 
.noselect1 {
  
    -webkit-touch-callout: none; 
      -webkit-user-select: none; 
       -khtml-user-select: none; 
         -moz-user-select: none; 
          -ms-user-select: none; 
              user-select: none; 
                                    
  }
  
.total {
    background-color: black;
    height: 2px;
    display: block;
    text-align: end;
    align-items: end;
    flex: auto;
    justify-content: end;
    width: 100% !important;
}


.numBlock {
    width: fit-content !important;
    height: fit-content !important;
    padding:20px;
    border-radius: 1px;
    border: 1px groove wheat;
  
}  

.Block {
    margin-bottom: 2px;
    margin-top: 2px;
    text-align: center;
    align-items: center;
    font-size: calc(18px + 2vmin);
    width: 50px !important;
    height: 50px !important;
    font-weight: bold;
    font-family: fantasy;
    padding:1px;
    border-radius: 5px;
    border: 3px groove wheat;
}

.Block:hover{
    border: 3px ridge wheat;
}



.Blank{
    text-align: center;
    align-items: center;
    font-size: calc(18px + 2vmin);
    width: 50px !important;
    height: 50px !important;
    font-weight: bold;
    font-family: fantasy;
}

.Color1 {
background-image: url("/public/images/planet_01.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;


}

.Color2 {
background-image: url("/public/images/planet_02.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;

}

.Color3 {
background-image: url("/public/images/planet_03.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
font-family: fantasy;
}

.Color4 {
background-image: url("/public/images/planet_04.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.Color5 {
background-image: url("/public/images/planet_05.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.Color6 {
background-image: url("/public/images/planet_06.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.Color7 {
background-image: url("/public/images/planet_07.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.Color8 {
background-image: url("/public/images/planet_08.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.Color9 {
background-image: url("/public/images/planet_09.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.ColorX {
    background-image: url("/public/images/planet_00.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}



.animation{
    display: none;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other on smaller screens (600px wide or less) */
@media screen and (max-width: 500px) {
    .w3-col {
      width: 100%;
    }
}