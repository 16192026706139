.App {
  text-align: center !important;
}

.Result{
  text-align: center !important;
  align-items: center !important;
  display: flex;
}



.tagline {
  color: #999;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 2px;
  vertical-align: middle;
  margin-top: 10px;
  font-weight: normal;
}

.tagline:after, .tagline:before {
  content: "";
  width: 45px;
  border-bottom: 1px solid;
  vertical-align: middle;
  display: inline-block;
}

.heading{
  text-align: center !important;
  align-items: center !important;
  display: flex;
  align-content: center !important;
  text-decoration: none;
}
a {
  text-decoration: none;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
 
}


/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;

  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} 
*/

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
