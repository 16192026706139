
/* .row div:nth-of-type(2n+1) {
    background: red;
}

.row div:nth-of-type(2n) {
background: lightblue;
} */

/* Responsive layout - makes the three columns stack on 
top of each other instead of next to each other on 
smaller screens (600px wide or less) */
/* @media screen and (max-width: 300px) {
     .col-k {
        width: 100% !important;
    } 
} */

.bigtext{
    font-family: fantasy !important;
    font-size: large;
}

.biggertext{
    font-family: fantasy !important;
    font-size: large;
}

.bright{
    margin-left: 200px;
}

.scoreCard{
    font-size:"40px" !important;
    width:"100px";
    display: flexbox;
    
    float:"left" !important;
}

@media only screen and (max-width: 450px) {
    [class*="col-"] {
        width: 100%;
    }
}